import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: '',
    profile: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setProfile: (state, action) => {
      state.profile = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setToken, setProfile } = userSlice.actions

export default userSlice.reducer