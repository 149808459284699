import { configureStore } from "@reduxjs/toolkit";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import deleteModalReducer from "../Reducers/DeleteModalReducer";
import UserReducer from "../Reducers/UserReducer";
import CaseFilterReducer from "../Reducers/CaseFilterReducer";
import CommunicationReducer from "../Reducers/CommunicationReducer";
import GlobalReducer from "../Reducers/GlobalReducer";

// Create a persist config object
const persistConfig = {
  key: "root",
  storage,
};

// Create the persisted reducers
const persistedUserReducer = persistReducer(persistConfig, UserReducer);

export default configureStore({
  reducer: {
    deleteModal: deleteModalReducer,
    user: persistedUserReducer,
    caseFilter: CaseFilterReducer,
    Communication: CommunicationReducer,
    Global: GlobalReducer,
  },
});
